import { render, staticRenderFns } from "./chatShare.vue?vue&type=template&id=703fb10e&scoped=true"
import script from "./chatShare.vue?vue&type=script&lang=js"
export * from "./chatShare.vue?vue&type=script&lang=js"
import style0 from "./chatShare.vue?vue&type=style&index=0&id=703fb10e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703fb10e",
  null
  
)

export default component.exports