<template>
    <el-container height="100%">
        <el-main>
            <div ref="div_scroll" style="overflow-y: auto; height:100%;">
                <div v-if="typ=='share'" style="margin-bottom: 10px;">
                    <el-row class="row-h row-h-first">
                        <el-col :span="2"></el-col>
                        <el-col :span="20" style="text-align: right; padding-right: 10px;">
                            <el-input 
                                            type="text" 
                                            v-model="shareLink" disabled>
                                            <div slot="append">
                                                <el-button icon="el-icon-copy-document" @click="copyLink">复制链接</el-button>
                                            </div>
                                            </el-input>
                        </el-col>
                        <el-col :span="2"></el-col>
                    </el-row>
                </div>
                <div v-if="typ=='page'" style="margin-bottom: 10px;">
                    <el-row class="row-h row-h-first">
                        <el-col :span="2"></el-col>
                        <el-col :span="20" style="text-align: right; padding-right: 10px;">
                            <div style="text-align: left"><h2>来自 {{ you }} 的对话分享</h2><hr></div>
                        </el-col>
                        <el-col :span="2"></el-col>
                    </el-row>
                </div>
                <div v-for="(chatone,index) in chatList" :key="chatone.id">
                    <el-row :class="{  
                            'row-h': true,  
                            'row-h-first': index === 0,  
                        }">
                        <el-col :span="2" style="text-align: right; padding-right: 10px;">
                            <el-button v-if="chatone.role == 'user'" type="warning" icon="el-icon-s-custom" circle></el-button>
                            <el-button v-else type="primary" icon="el-icon-user-solid" circle></el-button>
                        </el-col>
                        <el-col :span="20" style="text-align: left; padding-left: 10px;">
                            <el-row>
                                <el-col :span="24" class="el-col-title">{{chatone.role == 'user'?you:'启鹏Ai'}}</el-col>
                                <el-col :span="24" :class="{
                                    'col-content':true,
                                    'aiContent':chatone.role == 'user'
                                }"><vue-markdown :source="chatone.content"  :breaks="true"/>
                            <el-button type="text"
                                    @click="copyText(chatone.content)" 
                                    class="copyBtn"
                                    icon="el-icon-copy-document">复制</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="2"></el-col>
                    </el-row>
                    <el-row style="display: none;">
                        <el-col :span="24" style="display: none;">
                            <vue-markdown :source="copyContent" :breaks="true" ref="copyVueShare"/>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="row-h" v-if="messCont!=''">
                        <el-col :span="2" style="text-align: right; padding-right: 10px;">
                            <el-button type="primary" icon="el-icon-user-solid" circle></el-button>
                        </el-col>
                        <el-col :span="20" style="text-align: left; padding-left: 10px;">
                            <el-row>
                                <el-col :span="24" class="el-col-title">启鹏Ai</el-col>
                                <el-col :span="24" class="col-content"><vue-markdown :source="messCont"  :breaks="true"/></el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="2"></el-col>
                    </el-row>
                </div>
            </div>
            
        </el-main>
        <el-footer v-if="typ=='page'" height="50px">
            <el-row>
                <el-col :span="2"></el-col>
                <el-col :span="22" style="text-align: center;">
                    <el-button type="primary" 
                    :icon="iconClass" 
                    :disabled="isDisabledButton" @click="toQiPengAi">前往启鹏Ai</el-button>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </el-footer>
    </el-container>
</template>

<script>
import axios from 'axios'
import $conf from '@/apiconfig'
import VueMarkdown from 'vue-markdown';

    export default {
        name: 'chatShare',
        props:{
            vMarkG: {
                type: String,
                default: "",
            },
            typ:{
                type:String,
                default:"page"
            }
        },
        components: {
            VueMarkdown,
        },
        data()
        {
            return {
                content:"",
                chatList:[],
                // eventSource:null,
                messCont:'',
                isDisabledButton:false,
                you:"",
                shareLink:"",
                copyContent:""
            };
        }, 
        watch:{
            vMarkG(){
                // this.$message.info("init     "  + this.tid)
                if(this.vMarkG!=""){
                    this.chatList={};
                    this.init();
                }
            },
            chatList(){
                this.$nextTick(() => {
                    // 使用 $refs 访问 DOM 元素  
                    this.$refs.div_scroll.scrollTop = this.$refs.div_scroll.scrollHeight;  
                }); 
            },
            messCont(){
                this.$nextTick(() => {
                    // 使用 $refs 访问 DOM 元素  
                    this.$refs.div_scroll.scrollTop = this.$refs.div_scroll.scrollHeight;  
                }); 

            }
        },
        mounted(){
            // console.log(this.vMarkG+"   ||   "+this.typ)
            if(this.vMarkG!=""){
                this.chatList={};
                this.init();
            }
        },  
        computed: {  
            iconClass() {  
                return this.isDisabledButton ? 'el-icon-loading' : 'el-icon-s-promotion';  
            },  
        },
        methods:{
            init: async function(){
                try {
                    const loginUrl=$conf.dev.apiUrl+'chat/share/list';
                    const response = await axios.post(loginUrl, {
                        vmarkg:this.vMarkG
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.you = resD.msg;
                        this.shareLink = window.location.origin+"/#/chatShare/"+this.vMarkG
                        resD.data.forEach(chat => {
                            chat.content = chat.content.replace(/\\n/g, '<br>');
                        });
                        this.chatList=resD.data;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async copyLink(){
                try {
                    const shareLinText="叮！快来看看我和启鹏AI的奇妙对话～点击链接 "+this.shareLink+" -- 启鹏Ai，既能写文案、想点子，又能陪你聊天，答疑解惑，是你工作学习生活的智能伙伴！"
                    await navigator.clipboard.writeText(shareLinText);  
                    this.$message.success("链接复制成功。");  
                } catch (err) {
                    console.error(err);  
                    this.$message.info("链接复制失败。");  
                }  

            },
            // async copyText(content){
                
            //     this.copyContent = content;
            //     // 等待下一次 DOM 更新  
            //     await this.$nextTick();
            //     let markdownElement;
            //     // 获取 vue-markdown 组件渲染后的 HTML  
            //     if(Array.isArray(this.$refs.copyVueShare)){
            //         markdownElement = this.$refs.copyVueShare[0].$el;
            //     }else{
            //         markdownElement = this.$refs.copyVueShare.$el; 
            //     }  
            //     // 获取要复制的文本（这里我们假设是整个元素的 innerHTML）  
            //     const textToCopy = markdownElement.innerText || markdownElement.textContent;  
            
            //     try {  
            //         await navigator.clipboard.writeText(textToCopy);   
            //         // const msg = successful ? '复制成功' : '复制失败';  
            //         this.$message.success('复制成功')
            //     } catch (err) {  
            //         this.$message.error('复制失败')
            //     }  finally{
            //         this.copyContent=''
            //     }
            // },
            async copyText(content) {
                try {
        // 强制文档获取焦点
        window.focus(); // 使窗口聚焦
        document.body.focus(); // 或者直接聚焦到 body 元素
                    // 创建临时 DOM 元素，将 Markdown 内容解析为 HTML
                    const markdownElement = document.createElement('div');
                    markdownElement.innerHTML = content; // 将 Markdown 转换成 HTML

                    // 获取该元素中的纯文本内容（去除 HTML 标签）
                    const textToCopy = markdownElement.innerText || markdownElement.textContent;

                    // 使用 Clipboard API 复制解析后的纯文本
                    await navigator.clipboard.writeText(textToCopy);
                    this.$message.success('复制成功');
                } catch (err) {
                    console.error('Clipboard API failed, falling back to execCommand', err);

                    // 如果 Clipboard API 失败，使用 execCommand 作为回退
                    const markdownElement = document.createElement('div');
                    markdownElement.innerHTML = content; // 将 Markdown 转换成 HTML
                    const textToCopy = markdownElement.innerText || markdownElement.textContent; // 获取纯文本

                    // 创建一个 textarea 来支持 execCommand 复制操作
                    const textarea = document.createElement('textarea');
                    textarea.value = textToCopy; // 使用解析后的纯文本
                    textarea.style.position = 'fixed'; // 确保其不影响页面布局
                    textarea.style.left = '-9999px'; // 移动到屏幕外
                    document.body.appendChild(textarea); // 添加到文档中
                    textarea.select(); // 选中内容

                    try {
                        const success = document.execCommand('copy'); // 使用 execCommand 执行复制
                        this.$message[success ? 'success' : 'error'](success ? '复制成功' : '复制失败');
                    } catch (err) {
                        console.error('execCommand 失败', err);
                        this.$message.error('复制失败');
                    } finally {
                        // 清理 DOM
                        document.body.removeChild(textarea);
                    }
                }
            }


,
            toQiPengAi(){
                this.$router.replace('/')
            }
                
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
height: 100%;
overflow: hidden;
    color: #000;

}
.el-footer {
    background-color: #ffffff;
    color: #000;
    text-align: center;
    height: 120px;
    border-top: 1px solid #f4f4f4;
}
.div_init{
   margin-top: 30vh;
}
.div_init_t1{
   font-size: 20pt;
   font-weight: 700;
}
.div_init_t2{
    margin-top: 10px;
    /* padding-left: 25%; */
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
}
.el-main{
    overflow-y: auto;
    color: #000;
}
.row-h{
    min-height: 40px;
    margin-top: 20px;
}
.row-h-first{
    margin-top: 0;
}
.el-col-2{
    min-height: 40px;
    line-height: 40px;
}
.el-col-24{
    margin-top: 10px;
}
.el-col-title{
    font-weight: 600;
    margin-top: none;
}
.aiContent{
    padding: 10px;
    background: #f5f5f5;
    border-radius: 15px;
    /* line-height: 1.6; */
}
.col-content{
    line-height: 30px;
}
.col-rechat{
    text-align: right;
    margin-bottom: 15px;
}
</style>
